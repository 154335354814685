import React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <main>
      <div className="max-w-8xl sm:px-6 lg:px-8">
        <div className="px-4 sm:px-0">
          {/* <div className="border-2 border-dashed border-pink-200 grid h-screen place-items-center"> */}
          <div className="grid h-screen place-items-center">
            <section>
              <div className="gradient"></div>
              <div className="text-center">
                <h1 className="text-6xl tracking-tight font-bold text-gray-700 sm:text-5xl md:text-6xl dark:text-gray-200">
                  <span className="block xl:inline drop-shadow-sm">
                    Product Design
                  </span>
                  <span className="block text-gray-200 xl:inline hues">
                    {" "}
                    on demand.
                  </span>
                </h1>
                <p className="max-w-md mx-auto text-base text-gray-700 font-light sm:text-lg md:mt-5 md:text-2xl md:max-w-3xl dark:text-gray-400 ">
                  We are a creative studio that leverages modern technology and
                  practices to help companies of all sizes deliver with speed
                  and confidence.
                </p>
                <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
                  {/* <div className="mt-3 rounded-md sm:mt-0 sm:ml-3">
                    <a
                      href="#"
                      className="w-full flex items-center justify-center px-8 py-3 border border-gray-300 text-base font-medium rounded-md text-indigo-700 bg-white md:py-4 md:text-lg md:px-10 dark:text-gray-200 dark:bg-gray-900 dark:border-gray-700 dark:hover:bg-black"
                    >
                      Demo{" "}
                    </a>
                  </div> */}
                  {/* <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                    <a
                      href="#"
                      className="w-full flex items-center justify-center px-8 py-3 border border-gray-700 text-base font-medium rounded-md text-purple-200 bg-purple-900 hover:bg-gray-50 md:py-4 md:text-lg md:px-10 dark:text-gray-200 dark:bg-gray-900 dark:hover:bg-black"
                    >
                      Coming soon{" "}
                    </a>
                  </div> */}
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </main>
    {/* <hr></hr> */}
    {/* <section className="mx-auto max-w-screen-xl">
      <h2 className="text-4xl text-center tracking-tight font-extrabold text-gray-700 sm:text-5xl md:text-6xl dark:text-gray-200">
        Services
      </h2>
      <p className="text-xl my-10 text-gray-400 text-center">
        We offer a range of services to get you going.
      </p>
      <div className="grid grid-rows-2 grid-flow-col gap-1">
        <div className="flex items-center w-full h-72 p-12 text-center text-xl text-gray-500 bg-white dark:bg-gray-900 dark:hover:bg-gray-700">
          <div className="grid auto-rows text-center w-full">
            <h3 className="text-gray-800 text-2xl mb-2 dark:text-gray-300">
              Product Design
            </h3>
            <p className="text-gray-400">
              We help our customers deliver world class products.
            </p>
          </div>
        </div>
        <div className="flex items-center w-full h-72 p-12 text-center text-xl text-gray-500 bg-white dark:bg-gray-900 dark:hover:bg-gray-700">
          <div className="grid auto-rows text-center w-full">
            <h3 className="text-gray-800 text-2xl mb-2 dark:text-gray-300">
              Prototyping
            </h3>
            <p className="text-gray-400">
              Let us help you bring your vision to life with advanced
              prototyping techniques.
            </p>
          </div>
        </div>
        <div className="flex items-center w-full h-72 p-12 text-center text-xl text-gray-500 bg-white dark:bg-gray-900 dark:hover:bg-gray-700">
          <div className="grid auto-rows text-center w-full">
            <h3 className="text-gray-800 text-2xl mb-2 dark:text-gray-300">
              User Experience
            </h3>
            <p className="text-gray-400">
              We can assist with improving the user experience of your
              application.
            </p>
          </div>
        </div>
        <div className="flex items-center w-full h-72 p-12 text-center text-xl text-gray-500 bg-white dark:bg-gray-900 dark:hover:bg-gray-700">
          <div className="grid auto-rows text-center w-full">
            <h3 className="text-gray-800 text-2xl mb-2 dark:text-gray-300">
              Branding
            </h3>
            <p className="text-gray-400">
              Need help with a new or existing brand? We are here to help you
              get started or take it to the next level.
            </p>
          </div>
        </div>
        <div className="flex items-center w-full h-72 p-12 text-center text-xl text-gray-500 bg-white dark:bg-gray-900 dark:hover:bg-gray-700">
          <div className="grid auto-rows text-center w-full">
            <h3 className="text-gray-800 text-2xl mb-2 dark:text-gray-300">
              Content Management
            </h3>
            <p className="text-gray-400">
              Enabling our customers with tools to allow them to management
              their assets on their own is very powerful.
            </p>
          </div>
        </div>
        <div className="flex items-center w-full h-72 p-12 text-center text-xl text-gray-500 bg-white dark:bg-gray-900 dark:hover:bg-gray-700">
          <div className="grid auto-rows text-center w-full">
            <h3 className="text-gray-800 text-2xl mb-2 dark:text-gray-300">
              Subscription Services
            </h3>
            <p className="text-gray-400">
              Need a staff Designer for a project? We offer a Subscription model
              to fit your needs.
            </p>
          </div>
        </div>
      </div>
    </section> */}

    {/* <p>
      <Link to="/page-2/">Go to page 2</Link>
    </p>
    <p>
      <Link to="/using-typescript/">Go to "Using TypeScript"</Link>
    </p> */}
  </Layout>
)

export default IndexPage
